@font-face {
  font-family: 'RijksSans';
  src: url('./assets/ro_font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  display: block;
  padding: 0;
  background: #fff;
  font-family: RijksSans, Sans, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  width: 100%;
  margin: 0;
}

input {
  margin: 0 5px;
}

a {
  color: rgb(1, 104, 155);
}

thead.MuiTableHead-root {
  background: #CCE0F1;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
  padding: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: #01689B;
  text-transform: none;
  font-weight: bold;
  padding: 3px 6px;
  word-break: normal;
  background-image: url('./assets/file-download-solid.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-x: 13px;
  background-position-y: 4px;
  height: 30px;
  min-width: 40px;
}

td.MuiTableCell-root.MuiTableCell-body {
  padding: 10px;
  word-break: break-word;
}


#page {
  flex: 1 0 auto;
  min-height: 95.2vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

div #panel-header {
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  color: white;
  background: rgb(1, 104, 155);
}

#header {
  font-size: 16px;
  border-bottom: 10px #CCE0F1 solid;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#header span {
  font-size: 22px;
}

#logo {
  margin-right: auto;
  margin-left: auto;
  width: 1px;
}

#logo img {
  width: 252px;
  margin-top: -65px;
  margin-bottom: 7px;
  margin-left: -20px;
}

#nav ul {
  padding: 18px 0 0 30px;
  background-color: #01689B;
  list-style-type: none;
  margin: 0px;
  display: flex;
  flex-direction: row;
}

#nav li a {
  margin: 0 5px;
  color: #fff;
  text-decoration: none;
  transition: background 0.2s linear;
  font-size: 0.9em;
  padding: 0.7rem 1rem;
  line-height: 1;
  display: block;
  font-weight: 600;
}

#selectedMenuItem {
  color: #1A1A1A !important;
  background-color: #CCE0F1;
}

#menu-text {
  padding: 0.2em 30px 0.2em 30px;
  font-size: 1.25em;
  max-width: 100%;
  float: left;
  background: #E5F0F9;
  font-weight: bold;
  line-height: 1;
  color: #3A3A3A;
}
